import {app} from './firebase'
import { getStorage, ref, getDownloadURL, uploadString } from "firebase/storage";


const getBase64MimeType = (encoded) => {
    let result = null
    if (typeof encoded !== 'string') {
        return result
    }
    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
    if (mime && mime.length) {
        result = mime[1]
    }
    return result
}

const getBase64Data = (encoded) => {
    const base64EncodedString = encoded.replace(/^data:\w+\/\w+;base64,/,'')
    return base64EncodedString
}



 export const uploadPublicImage = async (fileName, uid , imageData, timestamp) => {
    const storage = getStorage(app);
    const path = `${uid}/${timestamp}/${fileName}`;
    const fileRef = ref(storage,path);

    const contentType = getBase64MimeType(imageData)
    const photoData = getBase64Data(imageData)

    const imgOptions = {
        contentType,
    }

    const uploadTask = uploadString(fileRef, photoData, 'base64', imgOptions);
    await uploadTask;   

    // Get a public URL 
    return await getDownloadURL(fileRef);
}





