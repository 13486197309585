// Import FirebaseAuth and firebase.
import React, { useEffect, useState, useContext } from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "./Connectors/firebase"
import {Dashboard} from './Runtime';
import { activateFeatures } from './Connectors/firebase';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { AppBar } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'
import { lazy, Suspense } from 'react'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useNavigate, Navigate } from 'react-router-dom'
import ResponsiveMenu from './Utils/ResponsiveMenu';
import Login from './Login';

import Footer from './GlobalBlocks/Footer';
import { AuthContext } from "./Connectors/context.js";

import MultiLingualContent from './Utils/MultilingualContent';

export default function Signup() {

    const { user, uid } = useContext(AuthContext); 


    const [scrollbar, setScrollbar] = useState(null)
    const [transparent, setTransparent] = useState(true)
    const [scrolled, setScrolled] = useState(false)
    const [top, setTop] = useState(null)


    
    
    return (<div>
        {!!user ? (
          <Navigate to={{ pathname: "/app", props:{user, uid}}} />
        ) : (
            <React.Fragment>
            
            <Helmet>
            <meta charset="utf-8" />
            <meta http-equiv="Content-Language" content={<MultiLingualContent contentID="lang"/>}/>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1, minimal-ui"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="canonical" href="https://dazzle.rocketme.ai" />
            <meta
                name="keywords"
                content={<MultiLingualContent contentID="meta_keywords"/>}
            />
            <meta
                name="description"
                content={<MultiLingualContent contentID="meta_content"/>}
            />
           
            <title>RocketMe - Get Inspired</title>
            </Helmet>
            <Scrollbars
                ref={(e) => {
                if (e !== null) {
                    setScrollbar(e)
                }
                }}
                renderView={(props) => (
                <div
                    {...props}
                    style={{
                    ...props.style,
                    }}
                />
                )}
                onScroll={(e) => {
                setTransparent(scrollbar.viewScrollTop < 100)
                setScrolled(true)
                }}
                autoHide
                style={{ width: '100%', height: '100vh' }}
            >
           
           <div style={{ width: "100%", height: "100%" }}>
            <div
              ref={(r) => r && setTop(r)}
              style={{
                height: "100vh",
                width: "100%",
                backgroundImage:
                  "linear-gradient(to right, #172038, #554391)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  maxWidth: "70%",
                }}
              >
                <img
                  src={"/rocketmelogov2WhiteMono.svg"}
                  alt="logo"
                  style={{ height: 120, width: 296, marginRight: 60 }} // Added marginRight
                />

                <img
                  src="/PartnerLogo.png"
                  alt="Partner logo"
                  style={{ height: 180, width: 180, marginRight: 60 }} // Added marginRight
                />
                <div style={{ padding: '0 16px' }}> {/* Added padding to the Login component */}
                  <Login/>
                </div>
        </div>
    </div>
  {scrolled && (
    <Suspense fallback={<CircularProgress />}>
      <Footer />
    </Suspense>
  )}
</div>

            
            </Scrollbars>
           </React.Fragment> 
          
        )}
      </div>
    );            
 }
      

 