import React from 'react';
import { useState, useEffect} from "react";

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, IconButton } from "@mui/material";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {app} from '../Connectors/firebase';

import { ScreenDialog } from './ScreenDialog';
import MultiLingualContent from './MultilingualContent';

import { doc,  getFirestore,  setDoc, getDoc, deleteDoc,updateDoc} from "firebase/firestore"

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import TwitterIcon from '@mui/icons-material/Twitter';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TranslateIcon from '@mui/icons-material/Translate';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DeleteOutlineTwoTone } from '@material-ui/icons';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';

import RestoreIcon from '@mui/icons-material/Restore';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const db = getFirestore(app);
/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

const paperColors= ["#feb236","#d64161","#ff7b25","#6b5b95","#f7786b","#92a8d1","#034f84","#f7cac9","#c1946a","#3e4444","#82b74b","#405d27"]
const iconColors = ["#FFFFFF","#000000","#FFFFFF","#FFFFFF","#000000","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#000000"]
const backgroundColors= ["#6b5b95","#feb236","#d64161","#ff7b25","#92a8d1","#034f84","#f7cac9","#f7786b","#3e4444","#82b74b","#405d27","#c1946a"]
const fontColors = ["#FFFFFF","#000000","#FFFFFF","#FFFFFF","#000000","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#000000"]

var colorFinal=0

export default function Card(props)
{
    const[cardContent, setCardContent] = useState(props.content);
    const[userPrompt, setUserPrompt] = useState(props.userPrompt);
    const[cardId, setCardId] = useState(props.cid);
    const[wantsSwitch, setWantsSwitch] = useState(false);
    const[wantsToDeleteCard, setWantsToDeleteCard] = useState(false);
    const[DisplayMessage, setDisplayMessage] = useState(undefined);
    const[destinationProject, setDestinationProject] = useState("");
    const[love, setlove] = useState(props.love);
    const [openSnackbarClipboard, setOpenSnackbarClipboard] = useState(false);
    const[editIt, setEditIt] = useState(false);
    const[tempCardContent, setTempCardContent] = useState("");
    const[url, setUrl] = useState(props?.url ? props.url : null);

    
    colorFinal = props.color();
    
    const pColor = paperColors[colorFinal]
    const iColor = iconColors[colorFinal]
    const bColor = backgroundColors[colorFinal]
    const fColor = fontColors[colorFinal]


    const handleFeedbackForCard=(args)=>
    {
        args = args.replace(/\n/g,"");
        props.sectionSelectorCB("Feedback", args)
    }
    const deleteIt = (args)=>{
        setWantsToDeleteCard(true);
        const dialogMessage ={
            title:<MultiLingualContent contentID="delete_card_title"/>,
            msg: <MultiLingualContent contentID="delete_card_description"/>,
            buttons: {
                b1: {
                    text: <MultiLingualContent contentID="ok"/>,
                    section: null,
                    cb: props.cb
                },
                b2: {
                    text: <MultiLingualContent contentID="cancel"/>,
                    section: null,
                    cb: handleCloseDeleteCard
                },
                b3: {
                    text: <MultiLingualContent contentID="send_feedback"/>,
                    section: "Send feedback",
                    args: args,
                    cb: handleFeedbackForCard
                },
            },
            other:{
                select:null
            },
            handleClose: handleCloseDeleteCard
        }
        setDisplayMessage(dialogMessage);

    }

    const handleCloseDeleteCard = () => {
        setWantsToDeleteCard(false)
      };
    const loveIt = async ()=>{
       
        await updateDoc(doc(db,"users", props.uid, "projects",props.projectSelected, props.col, props.cid),
        {
            love:!love
        }).catch((e)=>{
            console.log(e);
        });

        setlove(!love);

    }

    const improveIt=(args)=>{

        args = args.replace(/\n/g,"");
        props.sectionSelectorCB("Improved Texts", args)
    }

    const translateIt=(args)=>{
        args = args.replace(/\n/g,"");
        props.sectionSelectorCB("Translations", args)
    }


    const wantsToSwitch = () =>{
        setWantsSwitch(true);

        const dialogMessage ={
            title:<MultiLingualContent contentID="select_project"/>,
            msg: <MultiLingualContent contentID="select_project_dialog_msg"/>,
            buttons: {
                b1: {
                    text: <MultiLingualContent contentID="ok"/>,
                    section: null,
                    cb: switchProject
                },
                b2: {
                    text: <MultiLingualContent contentID="cancel"/>,
                    section: null,
                    cb: handleClose
                },
            },
            other:
            {
                select:{
                    list:props.projectList,
                    selected: props.projectSelected
                }
            },
            handleClose: handleClose
        }
        setDisplayMessage(dialogMessage);
    }

    const switchProject = (destProj) => {
        //Create collection for section 
        handleClose();

        getDoc(doc(db,"users", props.uid, "projects",props.projectSelected, props.col, props.cid)).then((oldDoc)=>{

                setDoc(doc(db,"users", props.uid, "projects",destProj, props.col, props.cid),oldDoc.data()).then(()=>{
                    deleteDoc(doc(db,"users", props.uid, "projects",props.projectSelected, props.col, props.cid));
                    setWantsSwitch(false)
                    console.log("Success");
                }).catch((e)=>{
                    setWantsSwitch(true)
                    console.log(e);
                    const txt = <MultiLingualContent contentID="select_project_alert_text"/>;
                    alert(txt);
                });

        }).catch((e)=>{
            setWantsSwitch(true)
            console.log(e);
            const txt = <MultiLingualContent contentID="select_project_alert_text"/>;
            alert(txt);
        });
        //Copy document from section to section
    }

    const saveCard = async (newCardContent) => {
        //Create collection for section
        const card = doc(db,"users", props.uid, "projects",props.projectSelected, props.col, props.cid);
        await updateDoc(card, {
            text: newCardContent,
            edited: true,
            });

        setCardContent(newCardContent);
        setEditIt(false);
    }

    const handleClose = () => {
        setWantsSwitch(false)
      };


    return(<>
        {
            wantsSwitch ?  <ScreenDialog DisplayError={DisplayMessage}/> : (null)
        }
        {
            wantsToDeleteCard ? <ScreenDialog DisplayError={DisplayMessage}/> : (null)
        }
            <Grid item xs={12} md={6} lg={6} >
                <Paper   sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: pColor}}>
                    <Box   sx={{ 
                        backgroundColor:bColor,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign:'center',
                        pt:1,
                        mb:0
                    }}>
                        <Box  sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign:'center',
                            backgroundColor:bColor,
                            color:fColor,
                            pt:1,
                            pb:1,
                            pr:1,
                            pl:1              
                        }}>
                            {
                                editIt==true?
                                    <div >
                                        <TextField
                                        variant="filled"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            placeholder={cardContent}
                                            value={tempCardContent!==""?tempCardContent:cardContent}
                                            onChange={(e) => setTempCardContent(e.target.value)}
                                            autoFocus={true}

                                        />
                                    </div>
                                :
                                <div >{cardContent}</div>
                            }
                        </Box>
                        <Stack spacing={0} sx={{mt:1}} direction="row" justifyContent="flex-end">
                             {
                                editIt==false?
                                <div>
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="delete"/>} component="span" size="small" onClick={()=>{deleteIt(cardContent)}}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="switch_project"/>} component="span" size="small" onClick={ ()=>{wantsToSwitch()}}>
                                        <DriveFileMoveIcon />
                                    </IconButton>
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="love"/>} component="span" size="small" onClick={ ()=>{loveIt()}}>
                                        {love?
                                        <FavoriteIcon style={{ color: 'red' }}/>:<FavoriteBorderIcon />
                                        }
                                    </IconButton>
                                    {props.userPrompt!=="" ?
                                        <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="redo"/>} component="span" size="small" onClick={ () => {props.textInputCB(userPrompt)}}>
                                            <AutorenewIcon />
                                        </IconButton>
                                        :(null)
                                    }
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="copy"/>} component="span" size="small" onClick={ () => {navigator.clipboard.writeText(cardContent); setOpenSnackbarClipboard(true)}}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="twit"/>} component="span" size="small" onClick={() => window.open('https://twitter.com/intent/tweet?text=Getting inspired by @RocketMe: '+cardContent+'&url=https://rocketme.es', "_blank")}>
                                        <TwitterIcon />
                                    </IconButton>
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="improve"/>} component="span" size="small" onClick={ ()=>{improveIt(cardContent)}}>
                                        <AutoFixHighIcon />
                                    </IconButton>
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="translate"/>} component="span" size="small" onClick={ ()=>{translateIt(cardContent)}}>
                                        <TranslateIcon />
                                    </IconButton>
                                    <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="edit"/>} component="span" size="small" onClick={ ()=>{setEditIt(!editIt)}}>
                                        <EditIcon />
                                    </IconButton>
                                    </div>
                                    :
                                    <div>
                                        <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="save"/>} component="span" size="small" onClick={ ()=>{saveCard(tempCardContent)}}>
                                            <SaveIcon />
                                        </IconButton>                                        
                                        <IconButton  style={{color:iColor}} aria-label={<MultiLingualContent contentID="save"/>} component="span" size="small" onClick={ ()=>{setEditIt(!editIt)}}>
                                            <RestoreIcon />
                                        </IconButton>
                                    </div>
                                }

                        </Stack>
                    </Box>
                </Paper>
            </Grid>

            <Snackbar open={openSnackbarClipboard} autoHideDuration={2000} onClose={()=>{setOpenSnackbarClipboard(false)}}>
                <Alert onClose={()=>{setOpenSnackbarClipboard(false)}} severity="success" sx={{ width: '100%' }}>
                    <MultiLingualContent contentID="sent_to_clipboard_text"/>
                </Alert>
            </Snackbar>

            </>
    )
}