import React from 'react';
import { useState, useEffect,useContext} from "react";
import { useNavigate } from "react-router-dom";

import { app } from "../Connectors/firebase";
import { doc, query, onSnapshot, getFirestore, connectFirestoreEmulator, deleteDoc,collection} from "firebase/firestore"

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import { Box } from "@mui/material";

import FeatureTitle from '../Utils/featureTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import {generateRecognizers} from '../Connectors/firebase';



import CircularProgress from '@mui/material/CircularProgress';
import CardImg from '../Utils/CardImg';
import {ScreenDialog} from '../Utils/ScreenDialog';

import { LanguageContext } from "../Utils/multilingualCtx";

import MultiLingualContent from '../Utils/MultilingualContent';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import createList from '../Utils/CreateList';
import axios from "axios";
import { FormControl, InputLabel, Typography } from "@mui/material";
import { uploadPublicImage } from '../Connectors/storage';

const db = getFirestore(app);

/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

function mapLng(lng)
{
    switch(lng)
    {
        case "spanish": return "ES-ES"; break;
        case "english": return "EN"; break;
        case "german": return "DE"; break;
        case "french": return "FR"; break;
        case "italian": return "IT"; break;
        case "catalan": return "ES-CA"; break;
    }
}


export default function NewRecognizer(props) {
  const [textInput, setTextInput] = useState("");
  const [RecognizerOutput, SetRecognizerOutput] = useState([]);
  const [DisplayError, setDisplayError] = useState(undefined);
  const [loading, setLoading]  = useState(false);
  const [selectedFile, setSelectedFile]  = useState({});

  const {language} = useContext(LanguageContext)
  const [destLng, setdestLng]  = useState(mapLng(language.toLowerCase()));

  const [image, setImage] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [previewSrc, setPreviewSrc] = useState(null);

  //Get current project generated texts.
  useEffect(() => {
        let unsuscribe=undefined;
        if(props.uid!==undefined)
        {
            const userProjects = query(collection(db, '/users/'+props.uid+'/projects/'+props.projectSelected+'/recognitions'));
            unsuscribe = onSnapshot(userProjects, (doc) => {  
                if (doc.size) {
                    let list=[];
                    let count = 0;
                    doc.forEach((doc) => {
                        list.unshift(createList(doc.id, doc.data().prompt, doc.data().love !== undefined ? doc.data().love : false, doc.data().userPrompt !== undefined ? doc.data().userPrompt:"", doc.data().url));
                        count= count+1;
                    });            
                    SetRecognizerOutput(list);

                }else{
                    SetRecognizerOutput([]);
                    console.log("Could not get docs... Retry, please.");
                }    
                
                if(props.args!==false)
                {
                    setTextInput(props.args);
                }
            });
        }
        return () => {
            unsuscribe();
        }
    }, [props.projectSelected]);

    const changeDestinationLanguage= (event) =>{
        setdestLng(event.target.value)
    }

  async function  generate()
  {
    setLoading(true); 
    
    
    var errorMessage=undefined

    if(image!==null)
    {
        let reader = new FileReader();
        reader.onload = function (e) {
            let pngBase64Prefix = 'data:image/png;base64,';
            let imageData = e.target.result;
            let fileName = image.name
            if(imageData.includes(';base64,')) {
            imageData = pngBase64Prefix + imageData.split(';base64,')[1];
            fileName = fileName.split('.')[0] + '.png';
            }

            uploadPublicImage(fileName,props.uid,imageData, new Date().getTime().toString()).then((url)=>{

            generateRecognizers({
                uid: props.uid,
                pid: props.projectSelected,
                url: url,
            }).then((result) => {

                try {
                    const jdata = JSON.parse(result.data);
                    
                    switch(jdata.error)
                    {
                    case "flaggedContent":
                            errorMessage ={
                                title:<MultiLingualContent contentID="moderation_alert_title"/>,
                                msg: <MultiLingualContent contentID="moderation_alert_text"/>,
                                buttons: {
                                    b1: {
                                        text: <MultiLingualContent contentID="ok"/>,
                                        section: null,
                                        cb: handleClose
                                    },
                                    b2: {
                                        text: <MultiLingualContent contentID="send_feedback"/>,
                                        section: "Send feedback",
                                        cb: handleFeedback
                                    },
                                },
                                other:{
                                    select:null
                                },
                                handleClose: handleClose
                            }
                            setLoading(false); 
                            setDisplayError(errorMessage)
                            break;
                        case "noText":
                            errorMessage ={
                                title:<MultiLingualContent contentID="no_text_provided_alert_title"/>,
                                msg: <MultiLingualContent contentID="no_text_provided_alert_text"/>,
                                buttons: {
                                    b1: {
                                        text: <MultiLingualContent contentID="ok"/>,
                                        section: null,
                                        cb: handleClose
                                    },
                                    b2: null,
                                },
                                other:{
                                    select:null
                                },
                                handleClose: handleClose
                            }
                            setLoading(false); 
                            setDisplayError(errorMessage)
                            break;
                        case "noCredits":
                            errorMessage={
                                title:<MultiLingualContent contentID="no_credits_alert_title"/>,
                                msg: <MultiLingualContent contentID="no_credits_alert_text"/>,
                                buttons: {
                                    b1: {
                                        text: <MultiLingualContent contentID="speak_to_your_admin"/>,
                                        section: null,
                                        cb: handleClose
                                    },
                                    b2: {
                                        text: <MultiLingualContent contentID="cancel"/>,
                                        section: null,
                                        cb: handleClose
                                    }
                                },
                                other:{
                                    select:null
                                },
                                handleClose: handleClose
                            }
                            setLoading(false); 

                            setDisplayError(errorMessage)
                            break;
                        case "noUser":
                            setLoading(false); 
                            break;
                        case "noProject":
                            setLoading(false); 
                            break;
                        default:
                            setLoading(false); 
                            break; 
                    }

                } catch(e) {  
                    const s=[RecognizerOutput.length+1,result.data];
                    SetRecognizerOutput((oldList) =>{
                        
                        setLoading(false); 

                        if(oldList[0]!==undefined)
                        {
                            return [...oldList,s]
                        }
                        else
                        {
                            return [s]
                        }
                    });
                }
            }).catch((error) => {
                setLoading(false); 
                console.log("ERROR");
                console.log(`error: ${JSON.stringify(error)}`);

            });
        })
    };
    reader.readAsDataURL(image);
    }
    else
    {
       errorMessage ={
            title:<MultiLingualContent contentID="no_image_provided_alert_title"/>,
            msg: <MultiLingualContent contentID="no_image_provided_alert_text"/>,
            buttons: {
                b1: {
                    text: <MultiLingualContent contentID="ok"/>,
                    section: null,
                    cb: handleClose
                },
                b2: null,
            },
            other:{
                select:null
            },
            handleClose: handleClose
        }
        setLoading(false); 

        setDisplayError(errorMessage)

    }
}

 

  function del(entryId)
  {
      //Borrar de la variable global y renderizar la pantalla otra vez
      let retIdx=-1;
      RecognizerOutput.forEach((val, idx, arr)=>{
        if(val.id === entryId)
        {
            retIdx = idx;
        }
      })
      if (retIdx > -1) {
        deleteDoc(doc(db,"users", props.uid, "projects",props.projectSelected,"recognitions", entryId)).then(()=>{
            RecognizerOutput.splice(retIdx, 1);
            SetRecognizerOutput([...RecognizerOutput]);
        }).catch((e)=>{

            console.log(e);
            alert(<MultiLingualContent contentID="error_deleting_completion_alert_text"/>)
        });

      }
      
  }


  const handleFeedback = () => {
    //Go to section ...
    setDisplayError(undefined)
    props.sectionSelectorCB("Feedback")
  };
  const handleClose = () => {
    setDisplayError(undefined)
  };


  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setPreviewSrc(URL.createObjectURL(e.target.files[0]));

  };


  
  return (
        <>
        {
            DisplayError!==undefined?(
                <ScreenDialog DisplayError={DisplayError}/>
                ):(null)
        }
        {
            props.projectList!=null?(
        <React.Fragment>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
  <FeatureTitle>
    <MultiLingualContent contentID="recognizer_feature_title" />
  </FeatureTitle>
  <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: 1 }}>
    <Box>
        <TextField
          variant="outlined"
          type="file"
          onChange={handleImageChange}
          InputProps={{ inputProps: { accept: "image/*" } }}
        />
        {previewSrc && (
          <>
            <Box mt={2} display="flex" alignItems="center" justifyContent="center">
              <img src={previewSrc} alt="Preview" style={{ maxWidth: "50%" }} />
            </Box>
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={generate}>
                {loading ? (
                  <>
                    <CircularProgress size="1rem" color="secondary" />
                    <div style={{ marginLeft: 7 }}>
                      <MultiLingualContent contentID="recognize_feature_button_text" />
                    </div>
                  </>
                ) : (
                  <div>
                    <MultiLingualContent contentID="recognize_feature_button_text" />
                  </div>
                )}
              </Button>
            </Stack>
          </>
        )}

      {prompt && (
        <Typography variant="subtitle1" component="div">
          Prompt: {prompt}
        </Typography>
      )}
    </Box>
  </Box>
</Paper>
        <Grid container spacing={2} sx={{mt:2, mb:2}}>
        {RecognizerOutput.length>0 ?(RecognizerOutput.map(entry =>
        
        <CardImg key = {entry.id} cid = {entry.id} content = {entry.text} love={entry?.love} url = {entry.url} cb={()=>del(entry.id)} color={()=> {return Math.floor(Math.random() * 11)}} projectSelected={props.projectSelected} uid={props.uid} projectList={props.projectList} sectionSelectorCB={props.sectionSelectorCB} col="recognitions" textInputCB={setTextInput} />
            )):(null)
        }
        
        </Grid>
        </React.Fragment>
            ):(null)
        }
        </>
    );
}