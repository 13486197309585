export const translations = {
    english: {
      lang:"EN",
      speak_to_your_admin: "Your company reached his quota, ask your admin to increase it.",
      current_credit_text: "Your current credit balance is: ",
      reserved: "Copyright: RocketMe.es. All Rights Reserved",
      account: "Account",
      get_credits: "Get Credits",
      log_out:"Logout",
      send_feedback: "Send Feedback",
      oai_disclamer: "Since you are interacting with an AI there are certain activities that are prohibited like using it for plagiarism. Hate, threatening, self-harm, sexual or violence language is prohibited.",
      ai_interaction_disclamer:"You are interacting with an AI. Plagiarism is prohibited. Click for more details.",
      dashboard_btn: "Dashboard",
      texts_btn: "Texts",
      headlines_btn: "Headlines",
      improvement_btn: "Text Improvement",
      summaries_btn: "Summaries",
      translation_btn: "Translations",
      tools_btn: "Tools",
      statement_btn: "Personal Statement",
      meeting_btn: "Meeting Notes",
      inspiration_btn: "Inspiration",
      recognizer_btn: "Recognizer",
      naming_btn: "Naming",
      taglines_btn: "Taglines",
      ads_btn: "Ads",
      brain_btn: "Brainstorms",
      questions_btn: "Questions",
      complete_btn: "Complete",
      delete_project:"Delete Project",
      confirm_deletion: "To confirm that you want to delete the project, write below the following number: ",
      cancel: "Cancel",
      created: "Created",
      name: "Name",
      short_description: "Short Description",
      credits_used: "Credits used",
      actions: "Actions",
      id: "Id",
      description: "Description",
      save: "Save",
      edit: "Edit",
      remove: "Remove",
      add: "Add",
      add_new_project:"Add new project",
      project_text: "Project '",//El proyecto .... 
      already_exists: " 'already exists",
      project_creation_error_alert: "We had a problem and project could not be created. Please check that you have funds and try again. Please, if the problem persists contact us.",
      incorrect_number_alert: "The number provided is incorrect!",
      pack_10000_text_title: "Great to try out RocketMe.",
      pack_10000_text_desc:"It can create up to 7.500 words.",
      pack_200000_text_title: 'Ideal for professional use',
      pack_200000_text_desc:'Whether you are a content creator or you need inspiration to create texts for your emails, presentations or papers, this pack is perfect for you. Create up to 200,000 words.',
      pack_800000_text_title: 'Ideal for professionals and companies',
      pack_800000_text_desc:'Fantastic for intensive creators, professionals and companies that need to generate large amounts of text automatically. Create up to 800,000 words.',
      most_popular: 'Most popular',
      go:"Go",
      moderation_alert_title: "Moderation alert",
      moderation_alert_text: "Text provided or text generated was flagged by the moderation process as harmful, explicit, sexual... Please beaware that using certain launguage is prohibited. Try changing your text or retry your petition. If problem persists and you think your text should not be flagged, please give us feedback and attach the text you used. Sorry for the inconvenience this may cause.",
      ok:"Ok",
      no_text_provided_alert_title: "No text provided",
      no_text_provided_alert_text:"We need a text in order to process your petition.",
      no_credits_alert_title:"Not enough credits",
      no_credits_alert_text:"Uops! Please make sure you have enough credits to continue.",
      error_deleting_completion_alert_text: "We had a problem deleting this. Please, try again. If the problem persists please contact us so we can assess the problem.",
      ad_feature_title: "Write what your product or service is about, where the ad will be published and your target:",
      ad_feature_placeholder_1:"A service to improve your personal branding on social networks",
      ad_feature_placeholder_2:"Content creators",
      ad_feature_placeholder_3:"Instagram",
      ad_feature_button_text:"Create Ad",
      brainstorm_feature_title: "Write what you want your brainstorm to be about:",
      brainstorm_feature_placeholder_1: "Write an essay about LGBT rights",
      brainstorm_feature_button_text: "Brainstorm",
      complete_feature_title:"Write a text to get ideas on how to complete it:",
      complete_feature_placeholder_1:"Government spending on NASA Around the nineteen-fifties to nineteen the world experienced a drastic change in innovations and technological development; however, the rate of change has had an iota to no change since then.",
      complete_feature_button_text:"Complete Text",
      feedback_feature_title:"Feel free to give us some feedback:",
      feedback_feature_placeholder_1:"We love to hear from our users so we can improve or add new features to our services.",
      feedback_feature_button_text:"Send Feedback",
      headline_feature_title:"Write some lines about a subject to create a headline:",
      headline_feature_placeholder_1:"Confusing, boring, vague, and dull headlines tell consumers that the rest of your content will be just as lifeless. You want people to assume you have the goods, so you can then deliver on the headline’s promise. If you do that often enough, you’ll build a stable of loyal readers — and, with any luck, customers",
      headline_feature_button_text:"Create Headline",
      improve_feature_title:"Write a text to improve/fix it:",
      improve_feature_placeholder_1:"Texts can be improbed in meny ways",
      improve_feature_button_text:"Improve/Fix",
      texts_feature_title:"Write a...:",
      texts_feature_placeholder_1:"Letter to contact for first time a potential client",
      texts_feature_button_text:"Create Text",
      naming_feature_title:"Describe the product and give a list of key words for the naming:",
      naming_feature_placeholder_1:"A home milkshake maker",
      naming_feature_placeholder_2:"Fast, healthy, compact",
      naming_feature_button_text:"Create names",
      statement_feature_title:"Write some clues below to get a full statement:",
      statement_feature_placeholder_1:"Open minded. Free. Like to read. Professional",
      statement_feature_button_text:"Create Statement",
      questions_feature_title:"Who will you interview?",
      questions_feature_placeholder_1:"An interview with a rockstar.",
      questions_feature_button_text:"Create Questions",
      questions_feature_number_label:"Number of questions:",
      recognizer_feature_title:"Upload an image to recognize it:",      
      recognize_feature_button_text:"Recognize",
      summary_feature_title:"Write a text to summarize it:",
      summary_feature_placeholder_1:"Summarising involves taking the main ideas from a piece of text and rewriting them in your own words. A summary is significantly shorter than the original text and tends to give an overview of a topic area. A good summary shows that you have understood the text.",
      summary_feature_button_text:"Summarize",
      summary_feature_simplify_text:"Simplify",
      tagline_feature_title:"Write a text that evocates what you do:",
      tagline_feature_placeholder_1:"I usually find novel ways to do things.",
      tagline_feature_button_text:"Create Tagline",
      translation_feature_title:"Write a text to translate:",
      translation_feature_placeholder_1:"With the internet making it easier for people to cross boundaries and learn about a lot of things from different countries like their culture and way of life, the urge to learn the language they speak becomes more interesting.",
      translation_feature_button_text:"Translate",
      from:"From:",
      to:"To:",
      select_project: "Select Project",
      select_project_dialog_msg:"Please, select the destination project for the selected text.",
      select_project_alert_text:"We had a problem moving your generated text. Please, try again and if the problem persists contact us",
      delete: "Delete",
      switch_project:"Switch Project",
      landing_main_copy_first_part_mobile_1: "Creating powerful communication doesn't have to be hard",
      landing_main_copy_first_part:"Creating ",
      landing_main_copy_final_part:" doesn't have to be hard",
      landing_main_copy_middle_part_1:"powerful brand communication",
      landing_main_copy_middle_part_2:"copies for social media",
      landing_main_copy_middle_part_3:"an engaging Tweet",
      landing_main_copy_middle_part_4:"a relevant post",
      landing_main_copy_middle_part_5:"an insightful personal statement",     
      landing_main_copy_subtext:"We inspire you to create content that engages your audience and drives conversions, whether it's headlines, taglines, captions, tweets, posts, brand copy, slogans, or something else.",
      get_free_account_btn_text:"Get your free account today",
      free_credits_line:"500 free credits. No credit card required",
      your_success_text:"Your success in 2 clicks",
      get_inspired:"Get Inspired",
      get_inspired_subtext:"Sometimes we run out of ideas or we might find hard to explain something in a carismatic way. RocketMe plays a huge role to help you find the inspiration that you need so you can focus on the core message you want to deliver.",
      advanced_language:"Advanced language AI",
      advanced_language_subtext:"Built on top of most advanced AI language model, RocketMe keeps improving everyday. Meaningfull headlines, taglines, statements... are created using credits that you can purchase by enrolling a plan.",
      get_inspired_now: "Get inspired now",
      get_inspired_now_subtext:"Ready to skyrocket your copywriting skills? Get your free account today with 500 free credits.",
      delete_project: "Delete Project",
      selected: "Selected",
      project:"Projects",
      add_project:"Add Project",
      need_to_create_project_before: "You need to create a project before continuing",
      sing_up_or_login: "Sing up or log in",
      meta_keywords: 'CV, curriculum, curriculum vitae, linkedin, job, job description, inspiration, statement, personal statement',
      meta_content: 'RocketMe is an inspiration assistant for you to upgrade your personal brand',
      cards_text_1_heading:"Get more attention",
      cards_text_1_caption:"We can inspire you to write more powerful, charismatic and relevant copy and get up to 75% more attention",
      cards_text_2_heading:"Save Time",
      cards_text_2_caption:"You're not alone when it comes to finding inspiration. We'll help you focus on the core message and raise the bar for you.",
      cards_text_3_heading:"Grammar proficiency",
      cards_text_3_caption:"RocketMe is amazing at languages! Let us take care of the grammar and syntax for you.",
      show_menu_alt: "Show menu",
      cookies_text: "This website uses cookies to enhance the user experience.",
      beta_disclamer_title: "Hello RocketMe is still in in beta preview",
      beta_disclamer_text: "This page is 100% functional but there may still be some minor errors, it may also be that the quality of some texts is not as expected. Users like you help us improve every day by sending their suggestions and what they see that fails, so before you get discouraged, we encourage you to send us your proposals, suggestions or complaints so that we can continue to improve and offer a better service every day.",
      sent_to_clipboard_text: "Copied to clipboard...",
      delete_card_title:"Delete text",
      delete_card_description:"The text will not be recoverable if it is deleted. Do you want to delete the text? In the event that the quality is poor, please consider giving us your feedback so that we can improve, for example by telling us what kind of response you would have expected. Thank you very much!",
      select_output_lng:"Output language:",
      redo:"Reuse",
      edit:"Edit",
      could_not_save_new_content:"We could not save the new content. Please try again in a few minutes. If the problem persists contact us at info@rocketme.ai",
      im_part_of_organization:"I'm part of an organization",      
      no_image_provided_alert_title:"Image error",
      no_image_provided_alert_text:"To use this feature you need to provide an image",
    },
    
    spanish: {
      lang:"ES-ES",
      speak_to_your_admin: "Tu compañía ha alcanzado el límite de creditos, por favor, contacta con tu administrador para añadir más creditos.",
      current_credit_text: "Tu balance actual de creditos es: ",
      reserved: "Copyright: RocketMe.es. Todos los derechos reservados",
      account: "Cuenta",
      get_credits: "Añadir creditos",
      log_out: "Salir",
      send_feedback: "Enviar sugerencias",
      oai_disclamer: "Como estás interactuando con una inteligencia artificial, hay ciertas actividades que están prohibidas, como usarla para el plagio. Se prohíbe el lenguaje de odio, amenazas, autolesiones, sexualidad o violencia.",
      ai_interaction_disclamer:"Estás interactuando con un ai. El plagio está prohibido. Haga clic para obtener más detalles.",
      dashboard_btn: "Dashboard",
      texts_btn: "Textos",
      headlines_btn: "Titulares",
      improvement_btn: "Mejorar texto",
      summaries_btn: "Resumir",
      translation_btn: "Traducir",
      tools_btn: "Herramientas",
      statement_btn: "Personal statement",
      meeting_btn: "Notas de reuniones",
      inspiration_btn: "Inspiración",
      recognizer_btn: "Reconocedor",
      naming_btn: "Naming",
      taglines_btn: "Eslóganes",
      ads_btn: "Anuncio",
      brain_btn: "Lluvia de ideas",
      questions_btn: "Cuestionarios",
      complete_btn: "Completar",
      delete_project:"Borrar projecto",
      confirm_deletion: "Porfavor, escribe el siguiente número para borrar el proyecto:",
      cancel: "Cancelar",
      created: "Creado",
      name: "Nombre",
      short_description: "Descripción corta",
      credits_used: "Créditos usados",
      actions: "Acciones",
      id: "Id",
      description: "Descripción",
      save: "Guardar",
      edit: "Editar",
      remove: "Borrar",
      add: "Añadir",
      add_new_project:"Añadir un nuevo proyecto",
      project_text: "El proyecto ",//El proyecto .... ......
      already_exists: " ya existe",
      project_creation_alert: "Tuvimos un problema y el proyecto no se pudo crear. Por favor, compruebe que tiene fondos y vuelva a intentarlo. Si el problema persiste, contacte con nosotros.",
      incorrect_number_alert: "El número introducido es incorrecto",
      pack_10000_text_title: "Genial para probar RocketMe.",
      pack_10000_text_desc:"Puede crear hasta 7.500 palabras.",
      pack_200000_text_title: 'Ideal para un uso profesional.',
      pack_200000_text_desc:'Ya seas creador de contenido o bien necesites inspiración para crear textos para tus correos, presentaciones o papers este pack te va como anillo al dedo. Crea hasta 200.000 palabras.',
      pack_800000_text_title: 'Ideal para profesionales y compañías.',
      pack_800000_text_desc:'Fantástico para creadores intensivos, profesionales y empresas que necesitan generar grandes cantidades de texto de forma automática. Crea hasta 800.000 palabras.',
      most_popular: 'El más popular.',
      go:"Elegir",
      moderation_alert_title: "Contenido inapropiado",
      moderation_alert_text: "El texto proporcionado o generado fue marcado por el proceso de moderación como dañino, explícito, sexual... tenga en cuenta que el uso de ciertos lenguajes está prohibido. Intente cambiar su texto o volver a enviar su solicitud. Si el problema persiste y cree que su texto no debería estar marcado, háganos saber y adjunte el texto que usó. Lo sentimos por los inconvenientes que esto pueda causar.",
      ok:"Ok",
      no_text_provided_alert_title: "Falta texto",
      no_text_provided_alert_text:"Necesitamos un texto para procesar tu petición.",
      no_credits_alert_title:"Créditos insuficientes",
      no_credits_alert_text:"Vaya! Asegúrate de que tienes créditos suficientes para continuar.",
      error_deleting_completion_alert_text: "Tuvimos un problema al eliminar esto. Por favor, inténtalo de nuevo. Si el problema persiste, contáctenos para que podamos evaluar el problema.",
      ad_feature_title: "Escribe sobre tu producto o servicio, dónde se publicará el anuncio y tu público objetivo:",
      ad_feature_placeholder_1:"Un servicio para mejorar tu marca personal en las redes sociales.",
      ad_feature_placeholder_2:"Creadores de contenido",
      ad_feature_placeholder_3:"Instagram",
      ad_feature_button_text:"Crear anuncio",
      brainstorm_feature_title: "Escribe de qué quieres que trate tu sesión de brainstorming:",
      brainstorm_feature_placeholder_1: "Escribe un ensayo sobre los derechos de lgbt",
      brainstorm_feature_button_text: "Lluvia de ideas",
      complete_feature_title:"Escribe un texto para obtener ideas de cómo completarlo.",
      complete_feature_placeholder_1:"El gasto del gobierno en la NASA a mediados de los años cincuenta hasta mediados de los años noventa fue una época en que el mundo experimentó un cambio drástico en las innovaciones y el desarrollo tecnológico; sin embargo, la tasa de cambio desde entonces ha sido mínima.",
      complete_feature_button_text:"Completar texto",
      feedback_feature_title:"Tu feedback es muy importante para nosotros",
      feedback_feature_placeholder_1:"Nos encanta recibir comentarios de nuestros usuarios para así poder mejorar o añadir nuevas funcionalidades a nuestros servicios.",
      feedback_feature_button_text:"Enviar Feedback",
      headline_feature_title:"Escribe unas líneas sobre un tema para crear un titular:",
      headline_feature_placeholder_1:"Titulates confusos, aburridos e inespecíficos dicen a los consumidores que el resto de su contenido será igual de insípido. Usted quiere que la gente asuma que usted tiene los productos, para que luego pueda cumplir con la promesa del titular. Si lo hace con suficiente frecuencia, construirá una base de lectores leales y, con suerte pasarán a serclientes.",
      headline_feature_button_text:"Crear titular",
      improve_feature_title:"Escribe un texto que quieras mejorar o corregir:",
      improve_feature_placeholder_1:"Los textos se pueden megorar en muxos aspectos",
      improve_feature_button_text:"Mejorar/Corregir",
      texts_feature_title:"Escribe un/a....:",
      texts_feature_placeholder_1:"Carta a un possible nuevo cliente.",
      texts_feature_button_text:"Crear texto",
      naming_feature_title:"Describe tu producto y especifica una lista de palabras claves para generar propuestas de naming:",
      naming_feature_placeholder_1:"Una batidora",
      naming_feature_placeholder_2:"Rápida, saludable, compacta",
      naming_feature_button_text:"Idear nombres",
      statement_feature_title:"Esribe una idea de como eres para generar un statement personal",
      statement_feature_placeholder_1:"Abierto. Libre. Me gusta leer. Profesional",
      statement_feature_button_text:"Crear Statement",
      questions_feature_title:"A quien vas a entrevistar?",
      questions_feature_placeholder_1:"Entrevista con una estrella del rock.",
      questions_feature_button_text:"Crear lista de preguntas",
      questions_feature_number_label:"Número de preguntas:",
      recognizer_feature_title:"Sube una imagen para reconocerla:",
      recognize_feature_button_text:"Reconocer",
      summary_feature_title:"Pega el texto que quieras resumir:",
      summary_feature_placeholder_1:"Resumir involucra tomar las ideas principales de un texto y reescribirlas en tus propias palabras. Un resumen es significativamente más corto que el texto original y suele dar una visión general de un área temática. Un buen resumen muestra que has comprendido el texto.",
      summary_feature_button_text:"Resumir",
      summary_feature_simplify_text:"Simplifiar",
      tagline_feature_title:"Escribe un texto que evoque tu forma de hacer las cosas:",
      tagline_feature_placeholder_1:"Suelo encontrar caminos originales para resolver problemas.",
      tagline_feature_button_text:"Crea un slogan",
      translation_feature_title:"Escribe un texto que quieras traducir:",
      translation_feature_placeholder_1:"Con el internet haciendo que sea más fácil para las personas cruzar fronteras y aprender sobre un montón de cosas de diferentes países como su cultura y forma de vida, el deseo de aprender el idioma que hablan se vuelve más interesante.",
      translation_feature_button_text:"Traducir",
      from:"De:",
      to:"A:",
      select_project: "Selecciona un Proyecto",
      select_project_dialog_msg:"Por favor, selecciona a qué proyecto quieres copiar el texto.",
      select_project_alert_text:"Tuvimos un problema al copiar el texto. Vuelve a intentarlo, si sigues teniendo problemas no dudes en contactar con nosotros.",
      delete: "Borrar",
      switch_project:"Cambiar de proyecto",
      landing_main_copy_first_part:"Crear ",
      landing_main_copy_final_part:" no tiene por qué ser difícil.",
      landing_main_copy_middle_part_1:"copies para RRSS",
      landing_main_copy_middle_part_2:"una comunicación poderosa",
      landing_main_copy_middle_part_3:"un Tweet que enganche",
      landing_main_copy_middle_part_4:"un post relevante",
      landing_main_copy_middle_part_5:"un statement que destaque",
      landing_main_copy_first_part_mobile_1: "Crear textos atractivos no tiene por qué ser complicado",
      landing_main_copy_subtext:"Te inspiramos para que crees contenido que atraiga a tu audiencia y genere conversiones, ya sean titulares, esloganes, tuits, publicaciones o lo que quieras.",
      get_free_account_btn_text:"Consigue ya tu cuenta gratuita",
      free_credits_line:"500 créditos gratis. No es necesaria una targeta para registratse.",
      your_success_text:"Tu éxito en 2 clicks",
      get_inspired:"Inspírate",
      get_inspired_subtext:"A veces nos quedamos sin ideas o podemos encontrar difícil explicar algo de manera carismática. RocketMe juega un papel enorme para ayudarte a encontrar la inspiración que necesitas para que puedas enfocarte en el mensaje central que quieres transmitir.",
      advanced_language:"Lenguaje IA avanzado",
      advanced_language_subtext:"Desarrollado sobre el modelo de lenguaje de inteligencia artificial más avanzado, rocketme mejora todos los días. Títulos, eslogans y declaraciones significativos se crean usando créditos que puede comprar al inscribirse en un plan.",
      get_inspired_now: "Inspírate ya",
      get_inspired_now_subtext:"¿Listo para impulsar tus habilidades de copywriting? Regístrate hoy y obtén 500 créditos gratis.",
      delete_project: "Borrar proyecto",
      selected: "Seleccionado",
      project:"Proyectos",
      add_project:"Añadir Proyecto",
      need_to_create_project_before: "Necesitas crear por lo menos un proyecto para continuar.",
      sing_up_or_login: "Entra o regístrate",
      meta_keywords: 'CV, curriculum, curriculum vitae, linkedin, trabajo, descripción de trabajo, inspiración, declaración, declración personal, cover letter, personal statement',
      meta_content: 'RocketMe es un asistente de inspiración para que mejores tu copywriting.',
      cards_text_1_heading:"Consigue más atracción",
      cards_text_1_caption:"Podemos inspirarte a escribir copies más poderosos, carismáticos y relevantes y conseguir hasta un 75% más de atención",
      cards_text_2_heading:"Ahorra en tiempo",
      cards_text_2_caption:"No estás solo cuando se trata de encontrar inspiración. Te ayudaremos a centrarte en el mensaje principal y a mejorar tu trabajo.",
      cards_text_3_heading:"Grammar proficiency",
      cards_text_3_caption:"¡Rocketme es increíble con los idiomas! Deja que nos encarguemos de la gramática y la sintaxis para ti.",
      show_menu_alt: "Mostrar menú",
      cookies_text: "Esta web usa cookies para mejorar la experiencia del usuario.",
      beta_disclamer_title: "Hello RocketMe todavía está en versión preliminar beta",
      beta_disclamer_text: "Esta página es 100% funcional pero aún puede haber algunos errores menores,también puede ser que la calidad de algunos textos no sea la esperada. Usuarios como tú nos ayudan a mejorar cada día enviándonos sus sugerencias y lo que ven que falla, así que antes de que te desanimes te animamos a que nos hagas llegar tus propuestas, sugerencias o quejas para que podamos seguir mejorando ofreciendo un mejor servicio cada día.",
      sent_to_clipboard_text: "Copiado al portapapeles...",
      delete_card_title:"Confirmar borrar texto",
      delete_card_description:"El texto no se va a poder recuperar si se borra. Quieres borrar el texto? En el caso de que la calidad sea mala considera porfavor dar-nos tu feedback para que podamos mejorar, por ejemplo eviándonos qué tipo de respuesta hubieras esperado. Muchas gracias!",
      select_output_lng:"Idioma del resultado:",
      redo:"Reutilizar",
      edit:"Editar",
      could_not_save_new_content:"No pudimos guardar el nuevo contenido. Porfavor intentalo más tarde. Si Sigue habiendo problemas envianos un correo a info@rocketme.es",
      im_part_of_organization:"Soy parte de una organización",
      no_image_provided_alert_title:"Error en la imagen",
      no_image_provided_alert_text:"Para usar el reconocedor proporciona una imagen.",
    },
  };