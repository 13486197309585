import React, { useState } from 'react';
import './firebaseui-styling.global.css'

import firebase from "firebase/compat/app";
import "firebase/auth";

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import MultiLingualContent from './Utils/MultilingualContent'

import TextField from '@mui/material/TextField';

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Typography from '@mui/material/Typography';



const Login = ({ setComponents }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      // Handle successful sign in (e.g., navigate to another page)
      navigate("/");
    } catch (error) {
      // Handle sign-in errors (e.g., display an error message)
      toast.error(`Error signing in`);

      //console.error("Error signing in:", error);
    }
  };

  return (
    <React.Fragment>
        <Paper elevation={3} sx=
        {{
          minWidth:300,
                  
        }}>
         
          <Typography
        component="div"
        align="center" // Center the text
        sx={{
          height: 20,
          color: '#654a86',
          fontWeight: 'normal',
          fontSize: 20,
          fontFamily: 'Roboto',
        }}
      >
        <MultiLingualContent contentID="sing_up_or_login" />
      </Typography>
          
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            bgcolor: 'background.paper',
            p: 3, // Add padding here
            minWidth: 300,
          }}>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Email"
                type="email"
                name="email"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Password"
                type="password"
                name="password"
                variant="outlined"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <Button variant="contained" type="submit" fullWidth>
                Sign In
              </Button>
            </form>
            <ToastContainer />

          </Box>
          
        </Paper>
    </React.Fragment>
  )
}

export default Login