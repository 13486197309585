import * as React from 'react';
import Divider from '@mui/material/Divider';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import TranslateIcon from '@mui/icons-material/Translate';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SavedSearch from '@mui/icons-material/SavedSearch';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ShortTextIcon from '@mui/icons-material/ShortText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PsychologyIcon from '@mui/icons-material/Psychology';
import GrainIcon from '@mui/icons-material/Grain';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import MultiLingualContent from '../Utils/MultilingualContent'
import RateReviewIcon from '@mui/icons-material/RateReview';

const auth = getAuth();

const logout = (navigate) =>{

  signOut(auth).then(() => {
    window.location.reload();
}).catch((error) => {
    console.log("Could not log out...");
});
}



export default function GetMenu (props){
  const [openInspiration, setOpenInspiration] = React.useState(false);
  const [openHelpers, setOpenHelpers] = React.useState(false);
  const [openTexts, setOpenTexts] = React.useState(false);

  const navigate = useNavigate();
  const oai_disclamer_text = MultiLingualContent({contentID:"oai_disclamer"});
  const beta_tester_disclamer_text = MultiLingualContent({contentID:"beta_tester_disclamer"});
  
  return(<>    
    <React.Fragment>
      <ListItemButton onClick={()=>{props.cb("Dashboard"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="dashboard_btn"/>} />
      </ListItemButton>
      <ListItemButton onClick={()=>{props.cb("Recognizer"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <SavedSearch />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="recognizer_btn"/>} />
      </ListItemButton>
    </React.Fragment>
    <Divider sx={{ my: 1 }} />
    <ListSubheader component="div" inset>
    <MultiLingualContent contentID="account"/>
    </ListSubheader>
    <ListItemButton onClick={()=>{props.cb("Feedback"); if(props.cbClose){props.cbClose()}}}>
      <ListItemIcon>
        <RateReviewIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLingualContent contentID="send_feedback"/>} />
    </ListItemButton>
    <ListItemButton onClick={() => logout(navigate)}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLingualContent contentID="log_out"/>} />
    </ListItemButton>
    <ListItemButton onClick={()=>{alert(oai_disclamer_text)}}>
      <ListItemText sx={{whiteSpace:'normal'}} primary={<MultiLingualContent contentID="ai_interaction_disclamer"/>}/>
    </ListItemButton>
  </>
  )
}




