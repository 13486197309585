import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, connectAuthEmulator } from "firebase/auth";

import {getFunctions} from 'firebase/functions'
import { httpsCallable, connectFunctionsEmulator  } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";


// Configure Firebase.
const config = {
    apiKey: "AIzaSyBJinBhmYxhdlRNBn8ljW__3IRy9nWxftY",
    authDomain: "rocket-me.firebaseapp.com",
    projectId: "rocket-me",
    storageBucket: "rocket-me.appspot.com",
    messagingSenderId: "300697384790",
    appId: "1:300697384790:web:30e0a98905b8254f5588f7",
    measurementId: "G-BPQE899K4V"
  };
export const app = firebase.initializeApp(config);

//if(process.env.NODE_ENV === "production")
//{
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LcQ98ohAAAAAH-8rv-8Z1sKGW2OlHHlVvVyF5Sp'),
    isTokenAutoRefreshEnabled: true
  });
//}

export const auth = getAuth(app);

//if(process.env.NODE_ENV !== "production")
//{
//  connectAuthEmulator(auth, "http://localhost:9099");
//}



const func = getFunctions(app);

//if(process.env.NODE_ENV !== "production")
//{
  //connectFunctionsEmulator(func, "localhost", 5001);
//}
export const getFeatures = httpsCallable(func,'getFeatures')
export const newUser = httpsCallable(func,'newUser')
export const generateStatements = httpsCallable(func,'generateStatements')
export const generateHeadlines = httpsCallable(func, 'generateHeadlines')
export const generateDefinitions = httpsCallable(func, 'generateDefinitions')
export const generateImprovements = httpsCallable(func, 'improveTexts')
export const generateSummaries = httpsCallable(func, 'summarizeTexts')
export const generateTagline = httpsCallable(func, 'generateTagline')
export const generateTranslations = httpsCallable(func, 'translateTexts')
export const generateAds = httpsCallable(func, 'generateAds')
export const generateNamings = httpsCallable(func, 'generateNaming')
export const generateBrainstorms = httpsCallable(func, 'generateBrainstorms')
export const generateQuestions = httpsCallable(func, 'generateQuestions')
export const generateRecognizers = httpsCallable(func, 'generateRecognizers')

export const generateTexts = httpsCallable(func, 'generateTexts')
export const completeText = httpsCallable(func, 'completeText')
export const sendFeedback = httpsCallable(func, 'sendFeedback')